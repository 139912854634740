import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { Case, Customer, PortfolioPaper, Subcontractor, User, Visit } from '../../../../../../_shared/model';
import { Reservation } from '../../../../../../_shared/model/reservation.model';
import { HelperService, MfToastService, StorageService } from '../../../../../../_shared/services';
import { BaseState } from '../../../../../../_shared/state/base/base.state';
import { CommonService, CustomerService, ResultService } from '../../../_services';
import { PfPBaseComponent } from '../pf-p-base/pf-p-base.component';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { CertificateDriversModel } from './model/certificate-drivers.model';
import { CertificateWeaponsModel } from './model/certificate-weapons.model';
import { CertificateWorkModel } from './model/certificate-work.model';
import { MdpsCertificateBase } from './model/mdps-certificate-base';

@Component({
    selector: 'app-pf-p-mdps-certificate',
    templateUrl: './pf-p-mdps-certificate.component.html',
    styleUrls: ['./pf-p-mdps-certificate.component.scss'],
})
export class PfPMdpsCertificateComponent extends PfPBaseComponent implements OnInit, OnDestroy {
    @Input() isNew = false;
    @Input() paper: PortfolioPaper;
    @Input() subcontractor: Subcontractor;
    @Input() customer: Customer;
    @Input() visit: Visit;
    @Input() reservation: Reservation;

    public wfForm: FormGroup;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);
    private onDestroy$ = new Subject<void>();
    private case: Case;
    public paperType = PaperType;

    public certificateWorkModel: CertificateWorkModel;
    public certificateDriversModel: CertificateDriversModel;
    public certificateWeaponsModel: CertificateWeaponsModel;
    public certificateBase: MdpsCertificateBase;

    constructor(
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private commonRest: CommonService,
        private rest: ResultService,
        private toast: MfToastService,
        public helper: HelperService,
        private datePipe: DatePipe,
        private comm: PfPCommnComunicationService,
        private store: Store,
        private storage: StorageService,
        private customerService: CustomerService,
    ) {
        super(helper);
    }

    ngOnInit(): void {
        this.createReport();
        if (!this.isNew) {
            this.certificateBase.getPaperData(this.paper.paperId || this.paper.id);
        }
        this.subscribeCommunications();
    }

    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    private createReport(): void {
        if (!this.visit) {
            this.visit = {
                customer: this.customer,
                reservationFrom: this.reservation.reservationFrom,
                contractor: this.reservation.contractor,
            };
        }

        this.visit.customer = { ...this.visit.customer, birthDate: this.customer.birthDate } as Customer;

        switch (this.paper.paperType) {
            case PaperType.WORK_CERTIFICATE:
                this.certificateWorkModel = new CertificateWorkModel(
                    this.paper,
                    this.fb,
                    this.onDestroy$,
                    this.rest,
                    this.helper,
                    this.toast,
                    this.visit,
                    this.reservation,
                    this.commonRest,
                    this.storage,
                );
                this.certificateBase = this.certificateWorkModel;
                break;
            case PaperType.DRIVER_CERTIFICATE:
                this.certificateDriversModel = new CertificateDriversModel(
                    this.paper,
                    this.fb,
                    this.onDestroy$,
                    this.rest,
                    this.helper,
                    this.toast,
                    this.visit,
                    this.reservation,
                    this.commonRest,
                    this.storage,
                );
                this.certificateBase = this.certificateDriversModel;
                break;
            case PaperType.WEAPON_CERTIFICATE:
                this.certificateWeaponsModel = new CertificateWeaponsModel(
                    this.paper,
                    this.fb,
                    this.onDestroy$,
                    this.rest,
                    this.helper,
                    this.toast,
                    this.visit,
                    this.reservation,
                );
                this.certificateBase = this.certificateWeaponsModel;
                break;
        }
    }

    private subscribeCommunications(): void {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveDocument().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.certificateBase.openPreview();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.certificateBase.openPreview(true);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.case = data.case;
            this.saveDocument().subscribe(() => {
                this.comm.signAndShow(this.paper, this.case);
            });
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveDocument(): Observable<any> {
        const prereservation: string = this.visit?.prereservationId || this.reservation.prereservationId;

        return of(this.visit).pipe(
            switchMap(visit =>
                this.rest
                    .getCustomerWorkData(visit.contractor.id, visit.visitId)
                    .pipe(tap(customerResponse => this.certificateBase.setCustomerWorkData(customerResponse))),
            ),
            switchMap(() => {
                if (this.isNew) {
                    return this.certificateBase.saveDocument$().pipe(
                        takeUntil(this.onDestroy$),
                        tap(
                            res => {
                                this.certificateBase.paper = {
                                    ...this.paper,
                                    id: res,
                                    prereservationId: prereservation,
                                    paperType: this.certificateBase.paper.paperType,
                                } as PortfolioPaper;
                                this.paper = this.certificateBase.paper;
                                this.comm.onPaperChanged.emit(this.certificateBase.paper);
                                this.toast.success('Spremembe so shranjene.');
                                this.isNew = false;
                                this.comm.dirty$.next(false);
                            },
                            () => {
                                this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.');
                            },
                        ),
                    );
                } else {
                    return this.certificateBase.updateDocument$().pipe(
                        takeUntil(this.onDestroy$),
                        tap(
                            () => {
                                this.toast.success('Spremembe so shranjene.');
                                this.comm.dirty$.next(false);
                            },
                            () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                        ),
                    );
                }
            }),
        );
    }
}
