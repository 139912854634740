import { KeyValue } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { EMPTY, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DATE_CONST } from '../../../../../../../_shared/constants/date-constants.const';
import { PortfolioPaper, Visit } from '../../../../../../../_shared/model';
import { Reservation } from '../../../../../../../_shared/model/reservation.model';
import { HelperService, MfToastService, StorageService } from '../../../../../../../_shared/services';
import { CommonService, ResultService } from '../../../../_services';
import { MdpsCertificateBase } from './mdps-certificate-base';

export interface ICertificateWorkModel {
    companyName: string;
    certificateNumber?: string;
    customerFullName: string;
    birthDate: string;
    jobTitle?: string;
    jobTitleCode?: string;
    appointmentDate: string;
    passText?: string;
    passWithLimitationsText?: string;
    temporaryFailsText?: string;
    permanentlyFailsText?: string;
    proposeNewJobText?: string;
    proposedMeasuresText?: string;
    placeOfSignature?: string;
    dateOfSignature?: string;
    indefinableStats?: string;
}

export class CertificateWorkModel extends MdpsCertificateBase<ICertificateWorkModel> implements ICertificateWorkModel {
    companyName: string;
    certificateNumber?: string;
    customerFullName: string;
    birthDate: string;
    jobTitle?: string;
    jobTitleCode?: string;
    appointmentDate: string;
    passText?: string;
    passWithLimitationsText?: string;
    temporaryFailsText?: string;
    permanentlyFailsText?: string;
    proposeNewJobText?: string;
    proposedMeasuresText?: string;
    placeOfSignature?: string;
    dateOfSignature?: string;
    indefinableStats?: string;

    public resultItems = this.getResultItems();
    public result6Items = this.getResult6Options();

    private commonRest: CommonService;
    private storage: StorageService;

    constructor(
        paper: PortfolioPaper,
        fb: FormBuilder,
        onDestroy$: Subject<void>,
        resultService: ResultService,
        helper: HelperService,
        toast: MfToastService,
        visit: Visit,
        reservation: Reservation,
        commonRest: CommonService,
        storage: StorageService,
    ) {
        super(paper, fb, onDestroy$, resultService, helper, toast, visit, reservation);
        this.commonRest = commonRest;
        this.storage = storage;

        this.commonRest.getContractorBasicInfo(this.storage.getSelectedContractor().id).subscribe(res => {
            this.form.controls['placeOfSignature'].setValue(res.postName);
        });
    }

    public createForm(): FormGroup {
        return this.fb.group({
            result: this.fb.control({ value: { key: '1' }, disabled: false }, Validators.required),
            resultDescription: this.fb.control({ value: null, disabled: false }),
            proposedMeasures: this.fb.control({ value: null, disabled: false }),
            dateOfSignature: this.fb.control({ value: moment().format(DATE_CONST.format.date.view), disabled: false }),
            placeOfSignature: this.fb.control({ value: null, disabled: false }),
        });
    }
    public getPaperData(paperId: number): void {
        this.resultService
            .getWorkCertificateData(this.visit.contractor.id, this.visit.visitId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                this.applyDataToForm(res);
            });
    }

    public saveDocument$(): Observable<string> {
        if (!this.form.valid) {
            return EMPTY;
        }
        const dto = this.getDataModelFromForm();
        return this.resultService.saveWorkCertificate(this.visit.contractor.id, this.visit.visitId, dto);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateDocument$(): Observable<any> {
        const paperId = this.paper.id ? this.paper.id : this.paper.paperId;
        const dto = this.getDataModelFromForm();
        return this.resultService.updateWorkCertificate(this.visit.contractor.id, this.visit.visitId, paperId, dto);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected getPaperPreview$(): Observable<any> {
        return this.resultService.getPortfolioPaper(this.visit.contractor.id, this.paper.id ? this.paper.id : this.paper.paperId);
    }

    public selectOption(item: KeyValue<string, string>): void {
        this.form.controls['result'].setValue(item);
    }

    public onDateChange(date: any): void {
        this.form.controls['dateOfSignature'].setValue(date.start.format(DATE_CONST.format.date.view));
    }

    private getResultItems(): Array<KeyValue<string, string>> {
        const itemsMap = new Array<KeyValue<string, string>>();
        itemsMap.push({ key: '1', value: '1. Izpolnjuje posebne zdravstvene zahteve za navedeno delovno mesto' });
        itemsMap.push({
            key: '2',
            value: '2. Izpolnjuje posebne zdravstvene zahteve za navedeno delovno mesto z naslednjimi omejitvami',
        });
        itemsMap.push({
            key: '3',
            value: '3. Začasno ne izpolnjuje posebnih zdravstvenih zahtev za navedeno delovno mesto',
        });
        itemsMap.push({
            key: '4',
            value: '4. Trajno ne izpolnjuje posebnih zdravstvenih zahtev za navedeno delovno mesto',
        });
        itemsMap.push({ key: '5', value: '5. Predlagano drugo delo (opredeliti delo in eventualne omejitve)' });
        itemsMap.push({
            key: '6',
            value: '6. Ocene izpolnjevanja posebnih zdravstvenih zahtev za navedeno delovno mesto ne moremo podati, ker:',
        });
        return itemsMap;
    }

    private getResult6Options(): Array<KeyValue<string, string>> {
        const itemsMap = new Array<KeyValue<string, string>>();
        itemsMap.push({
            key: '61',
            value: '6.1. ni izjave o varnosti z oceno tveganja (zdravstvene ogroženosti delovnega mesta)',
        });
        itemsMap.push({
            key: '62',
            value: '6.2. ni opravil(a) vseh pregledov in preiskav',
        });
        itemsMap.push({
            key: '63',
            value: '6.3. ni končano zdravljenje',
        });
        itemsMap.push({
            key: '64',
            value: '6.4. ni končana rehabilitacija',
        });

        return itemsMap;
    }

    private applyDataToForm(model: ICertificateWorkModel): void {
        if (model?.passText?.length) {
            this.form.controls['result'].setValue(this.resultItems.find(item => item.key === '1'));
            this.form.controls['resultDescription'].setValue(model?.passText);
        }

        if (model?.passWithLimitationsText?.length) {
            this.form.controls['result'].setValue(this.resultItems.find(item => item.key === '2'));
            this.form.controls['resultDescription'].setValue(model?.passWithLimitationsText);
        }

        if (model?.temporaryFailsText?.length) {
            this.form.controls['result'].setValue(this.resultItems.find(item => item.key === '3'));
            this.form.controls['resultDescription'].setValue(model?.temporaryFailsText);
        }

        if (model?.permanentlyFailsText?.length) {
            this.form.controls['result'].setValue(this.resultItems.find(item => item.key === '4'));
            this.form.controls['resultDescription'].setValue(model?.permanentlyFailsText);
        }

        if (model?.proposeNewJobText?.length) {
            this.form.controls['result'].setValue(this.resultItems.find(item => item.key === '5'));
            this.form.controls['resultDescription'].setValue(model?.proposeNewJobText);
        }

        if (model?.proposedMeasuresText?.length) {
            this.form.controls['proposedMeasures'].setValue(model?.proposedMeasuresText);
        }

        if (model?.placeOfSignature?.length) {
            this.form.controls['placeOfSignature'].setValue(model?.placeOfSignature);
        }

        if (model?.dateOfSignature?.length) {
            this.form.controls['dateOfSignature'].setValue(moment(model?.dateOfSignature).format(DATE_CONST.format.date.view));
            this.calendarOptions = { ...this.calendarOptions, startDate: moment(model?.dateOfSignature) };
        }
    }

    private getDataModelFromForm(): ICertificateWorkModel {
        const model = {
            companyName: `${this.visit.contractor.name}, ${this.visit.contractor.address != null ? this.visit.contractor.address : ''}`,
            certificateNumber: null,
            customerFullName: this.visit.customer.fullName,
            birthDate: moment(this.visit.customer.birthDate).format(DATE_CONST.format.date.api),
            jobTitle: this.customerWorkData.jobTitle,
            jobTitleCode: this.customerWorkData.jobCode,
            appointmentDate: moment(this.visit.reservationFrom).format(DATE_CONST.format.date.api),

            passText: '',
            passWithLimitationsText: '',
            temporaryFailsText: '',
            permanentlyFailsText: '',
            proposeNewJobText: '',
            indefinableStats: null,

            proposedMeasuresText: this.form.controls['proposedMeasures'].value?.length ? this.form.controls['proposedMeasures'].value : '',

            dateOfSignature:
                moment(this.form.controls['dateOfSignature']?.value, DATE_CONST.format.date.view).format(DATE_CONST.format.date.api) ||
                moment().format(DATE_CONST.format.date.api),
            placeOfSignature: this.form.controls['placeOfSignature']?.value || '',
        } as ICertificateWorkModel;

        const selectedKey = (this.form.controls['result'].value as KeyValue<string, string>).key;
        const resultDescription = this.form.controls['resultDescription'].value;

        switch (selectedKey) {
            case '1':
                model.passText = resultDescription?.length ? resultDescription : ' ';
                break;
            case '2':
                model.passWithLimitationsText = resultDescription?.length ? resultDescription : ' ';
                break;
            case '3':
                model.temporaryFailsText = resultDescription?.length ? resultDescription : ' ';
                break;
            case '4':
                model.permanentlyFailsText = resultDescription?.length ? resultDescription : ' ';
                break;
            case '5':
                model.proposeNewJobText = resultDescription?.length ? resultDescription : ' ';
                break;
            case '61':
                model.indefinableStats = '61';
                break;
            case '62':
                model.indefinableStats = '62';
                break;
            case '63':
                model.indefinableStats = '63';
                break;
            case '64':
                model.indefinableStats = '64';
                break;
        }
        return model;
    }
}
