<ng-container [ngSwitch]="paper.paperType">
    <ng-container *ngSwitchCase="paperType.WORK_CERTIFICATE" [ngTemplateOutlet]="certificateWorkTemplate"></ng-container>
    <ng-container *ngSwitchCase="paperType.DRIVER_CERTIFICATE" [ngTemplateOutlet]="certificateDriversTemplate"></ng-container>
    <ng-container *ngSwitchCase="paperType.WEAPON_CERTIFICATE" [ngTemplateOutlet]="certificateWeaponTemplate"></ng-container>
</ng-container>

<ng-template #certificateWorkTemplate>
    <form *ngIf="certificateBase.form as frm" [formGroup]="frm">
        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">Ocena</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <div class="d-flex row w-100 pl-4">
                    <ng-container *ngFor="let resultItem of certificateWorkModel.resultItems">
                        <label class="w-100" [ngClass]="!resultItem.key.includes('6') ? 'm-checkbox m-checkbox--state-brand' : 'last-option-margin'">
                            <input
                                *ngIf="!resultItem.key.includes('6')"
                                [checked]="frm.get('result')?.value?.key === resultItem.key"
                                (change)="certificateWorkModel.selectOption(resultItem)"
                                type="radio"
                                name="options"
                            />
                            {{ resultItem.value }}
                            <span></span>
                        </label>
                        <ng-container *ngIf="resultItem.key.includes('6')">
                            <ng-container *ngFor="let result6Item of certificateWorkModel.result6Items">
                                <label class="last-item-margin m-checkbox m-checkbox--state-brand w-100">
                                    <input (change)="certificateWorkModel.selectOption(result6Item)" type="radio" name="options" />
                                    {{ result6Item.value }}
                                    <span></span>
                                </label>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>

                <ng-container *ngIf="frm.get('result')?.value?.key as key">
                    <ng-container *ngIf="!key.includes('6')">
                        <div class="m--font-boldest p-2">Komentar:</div>
                        <textarea
                            [formControlName]="'resultDescription'"
                            bindLabel="value"
                            class="w-100 form-control m-input mf-t-CommentArea"
                        ></textarea>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">Predlagani ukrepi na področju varnosti in zdravja pri delu</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <textarea [formControlName]="'proposedMeasures'" bindLabel="value" class="w-100 form-control m-input mf-t-CommentArea"></textarea>
            </div>
        </div>
        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <h3 class="m-portlet__head-text">Kraj in dne</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body d-flex">
                <div class="d-flex w-100">
                    <span style="line-height: 32px" class="pr-2">
                        <i class="la la-map-marker m--font-inverse-light"></i>
                    </span>
                    <input
                        formControlName="placeOfSignature"
                        tabindex="3"
                        type="text"
                        autocomplete="off"
                        placeholder="Kraj"
                        class="form-control form-control-sm m-input"
                    />
                </div>
                <div class="d-flex w-100">
                    <span style="line-height: 32px" class="pr-2">
                        <i class="la la-calendar m--font-inverse-light"></i>
                    </span>
                    <input
                        [options]="certificateWorkModel.calendarOptions"
                        (selected)="certificateWorkModel.onDateChange($event)"
                        formControlName="dateOfSignature"
                        tabindex="3"
                        type="text"
                        autocomplete="off"
                        placeholder="Dne"
                        daterangepicker
                        class="form-control form-control-sm m-input"
                    />
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #certificateDriversTemplate>
    <form *ngIf="certificateBase.form as frm" [formGroup]="frm">
        <div class="row">
            <div class="col-md col-xl">
                <div class="m-portlet">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title w-100 p-2">
                                <h3 class="m-portlet__head-text">Številka veljavnega osebnega dokumenta kandidata</h3>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                        <input
                            formControlName="customerDocumentNumber"
                            type="text"
                            autocomplete="off"
                            placeholder=""
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md col-xl">
                <div class="m-portlet">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title w-100 p-2">
                                <h3 class="m-portlet__head-text">Evidenčna številka izvajalca</h3>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                        <input
                            formControlName="contractorCertificateNumber"
                            type="text"
                            autocomplete="off"
                            placeholder=""
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">Kategorije:</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <div class="d-flex flex-column w-100 pl-4">
                    <div class="d-flex flex-row w-100">
                        <ng-container *ngFor="let category of certificateDriversModel.firstGroupCategories">
                            <label class="m-checkbox m-checkbox--state-brand category-item">
                                <input
                                    [checked]="certificateDriversModel.selectedCategoriesArray.includes(category)"
                                    (change)="certificateDriversModel.selectCategoryOption(category)"
                                    type="checkbox"
                                />
                                {{ category }}
                                <span></span>
                            </label>
                        </ng-container>
                    </div>
                    <div class="d-flex flex-row w-100">
                        <ng-container *ngFor="let category of certificateDriversModel.secondGroupCategories">
                            <label class="m-checkbox m-checkbox--state-brand category-item">
                                <input
                                    [checked]="certificateDriversModel.selectedCategoriesArray.includes(category)"
                                    (change)="certificateDriversModel.selectCategoryOption(category)"
                                    type="checkbox"
                                />
                                {{ category }}
                                <span></span>
                            </label>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">1. Zdravniško spričevalo se nanaša na:</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <div class="d-flex flex-column w-100">
                    <ng-container *ngFor="let option of certificateDriversModel.resultOneOptions">
                        <label class="m-checkbox m-checkbox--state-brand ml-4">
                            <input
                                [checked]="frm.get('status1')?.value?.key === option.key"
                                (change)="certificateDriversModel.selectOptionOne(option)"
                                name="one"
                                type="checkbox"
                            />
                            {{ option.value }}
                            <span></span>
                        </label>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">2. Kandidat za voznika oziroma voznik je:</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <div class="d-flex flex-column w-100">
                    <span class="pl-3 pb-2">• telesno in duševno zmožen za voznika motornih vozil kategorije:</span>
                    <div class="d-flex flex-column w-100 pl-5">
                        <div class="d-flex flex-row w-100">
                            <ng-container *ngFor="let category of certificateDriversModel.firstGroupCategories">
                                <label class="m-checkbox m-checkbox--state-brand category-item">
                                    <input
                                        [checked]="certificateDriversModel.selectedCategories2aArray.includes(category)"
                                        (change)="certificateDriversModel.selectCategory2aOption(category)"
                                        type="checkbox"
                                    />
                                    {{ category }}
                                    <span></span>
                                </label>
                            </ng-container>
                        </div>
                        <div class="d-flex flex-row w-100">
                            <ng-container *ngFor="let category of certificateDriversModel.secondGroupCategories">
                                <label class="m-checkbox m-checkbox--state-brand category-item">
                                    <input
                                        [checked]="certificateDriversModel.selectedCategories2aArray.includes(category)"
                                        (change)="certificateDriversModel.selectCategory2aOption(category)"
                                        type="checkbox"
                                    />
                                    {{ category }}
                                    <span></span>
                                </label>
                            </ng-container>
                        </div>
                    </div>
                    <div [hidden]="!(certificateDriversModel.selectedCategories2aArray.length > 0)" class="pl-5 pb-2">
                        <span>z naslednjimi omejitvami:</span>
                        <input
                            formControlName="status2aLimitations"
                            type="text"
                            autocomplete="off"
                            placeholder="Omejitve"
                            class="form-control form-control-sm m-input"
                        />
                    </div>

                    <span class="pl-3 pb-2">• je začasno telesno in duševno nezmožen za voznika motornih vozil kategorije:</span>
                    <div class="d-flex flex-column w-100 pl-5">
                        <div class="d-flex flex-row w-100">
                            <ng-container *ngFor="let category of certificateDriversModel.firstGroupCategories">
                                <label class="m-checkbox m-checkbox--state-brand category-item">
                                    <input
                                        [checked]="certificateDriversModel.selectedCategories2bArray.includes(category)"
                                        (change)="certificateDriversModel.selectCategory2bOption(category)"
                                        type="checkbox"
                                    />
                                    {{ category }}
                                    <span></span>
                                </label>
                            </ng-container>
                        </div>
                        <div class="d-flex flex-row w-100">
                            <ng-container *ngFor="let category of certificateDriversModel.secondGroupCategories">
                                <label class="m-checkbox m-checkbox--state-brand category-item">
                                    <input
                                        [checked]="certificateDriversModel.selectedCategories2bArray.includes(category)"
                                        (change)="certificateDriversModel.selectCategory2bOption(category)"
                                        type="checkbox"
                                    />
                                    {{ category }}
                                    <span></span>
                                </label>
                            </ng-container>
                        </div>
                    </div>

                    <span class="pl-3 pb-2">• je trajno telesno in duševno nezmožen za voznika motornih vozil kategorije:</span>
                    <div class="d-flex flex-column w-100 pl-5">
                        <div class="d-flex flex-row w-100">
                            <ng-container *ngFor="let category of certificateDriversModel.firstGroupCategories">
                                <label class="m-checkbox m-checkbox--state-brand category-item">
                                    <input
                                        [checked]="certificateDriversModel.selectedCategories2cArray.includes(category)"
                                        (change)="certificateDriversModel.selectCategory2cOption(category)"
                                        type="checkbox"
                                    />
                                    {{ category }}
                                    <span></span>
                                </label>
                            </ng-container>
                        </div>
                        <div class="d-flex flex-row w-100">
                            <ng-container *ngFor="let category of certificateDriversModel.secondGroupCategories">
                                <label class="m-checkbox m-checkbox--state-brand category-item">
                                    <input
                                        [checked]="certificateDriversModel.selectedCategories2cArray.includes(category)"
                                        (change)="certificateDriversModel.selectCategory2cOption(category)"
                                        type="checkbox"
                                    />
                                    {{ category }}
                                    <span></span>
                                </label>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">3. Zdravstveni pregled za podaljšanje veljavnosti:</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <div class="d-flex flex-column w-100">
                    <ng-container *ngFor="let option of certificateDriversModel.resultThreeOptions">
                        <label class="m-checkbox m-checkbox--state-brand ml-4">
                            <input
                                [checked]="frm.get('status3')?.value?.key === option.key"
                                (change)="certificateDriversModel.selectOptionThree(option)"
                                type="radio"
                                name="three"
                            />
                            {{ option.value }}
                            <span></span>
                        </label>
                    </ng-container>
                </div>
                <div [hidden]="frm.get('status3')?.value?.key !== 'b'" class="pl-4">
                    <input
                        formControlName="status3Text"
                        type="text"
                        autocomplete="off"
                        placeholder="vnos časa, po katerem je treba opraviti pregled za podaljšanje veljavnosti"
                        class="form-control form-control-sm m-input"
                    />
                </div>
            </div>
        </div>

        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">
                            4. Prvi pregled po ugotovljeni začasni telesni in duševni nezmožnosti se opravi pri pooblaščenem izvajalcu zdravstvene
                            dejavnosti čez:
                        </h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <div>
                    <input
                        formControlName="status4Text"
                        type="text"
                        autocomplete="off"
                        placeholder="vnos časa, po katerem je treba opraviti pregled"
                        class="form-control form-control-sm m-input"
                    />
                </div>
            </div>
        </div>

        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">5. Na podlagi opravljenega pregleda posebna zdravstvena komisija predlaga:</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <div class="d-flex flex-column w-100">
                    <ng-container *ngFor="let option of certificateDriversModel.resultFiveOptions">
                        <label class="m-checkbox m-checkbox--state-brand ml-4">
                            <input
                                [checked]="certificateDriversModel.selectedCategories5Array.includes(option.key)"
                                (click)="certificateDriversModel.selectOptionFive(option)"
                                type="checkbox"
                            />
                            {{ option.value }}
                            <span></span>
                        </label>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <h3 class="m-portlet__head-text">Kraj in dne</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body d-flex">
                <div class="d-flex w-100">
                    <span style="line-height: 32px" class="pr-2">
                        <i class="la la-map-marker m--font-inverse-light"></i>
                    </span>
                    <input
                        formControlName="placeOfSignature"
                        type="text"
                        autocomplete="off"
                        placeholder="Kraj"
                        class="form-control form-control-sm m-input"
                    />
                </div>
                <div class="d-flex w-100">
                    <span style="line-height: 32px" class="pr-2">
                        <i class="la la-calendar m--font-inverse-light"></i>
                    </span>
                    <input
                        [options]="certificateDriversModel.calendarOptions"
                        (selected)="certificateDriversModel.onDateChange($event)"
                        formControlName="dateOfSignature"
                        type="text"
                        autocomplete="off"
                        placeholder="Dne"
                        daterangepicker
                        class="form-control form-control-sm m-input"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md col-xl-6">
                <div class="m-portlet">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title w-100 p-2">
                                <h3 class="m-portlet__head-text">Predsednik komisije</h3>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                        <input
                            formControlName="commissionChairman"
                            type="text"
                            autocomplete="off"
                            placeholder="Ime priimek"
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #certificateWeaponTemplate>
    <form *ngIf="certificateBase.form as frm" [formGroup]="frm">
        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">1. Zdravniško spričevalo se nanaša na:</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <div class="d-flex flex-column w-100">
                    <ng-container *ngFor="let option of certificateWeaponsModel.assessmentOptions">
                        <label class="m-checkbox m-checkbox--state-brand ml-4">
                            <input
                                [checked]="frm.get('assessmentResult')?.value?.key === option.key"
                                (change)="certificateWeaponsModel.selectOption(option)"
                                type="radio"
                                name="options"
                            />
                            {{ option.value }}
                            <span></span>
                        </label>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title w-100 p-2">
                        <h3 class="m-portlet__head-text">Obrazložitev ocene</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                <textarea
                    [formControlName]="'assessmentExplanation'"
                    bindLabel="value"
                    class="w-100 form-control m-input mf-t-CommentArea"
                ></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md col-xl">
                <div class="m-portlet">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title w-100 p-2">
                                <h3 class="m-portlet__head-text">Kraj rojstva</h3>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                        <input
                            formControlName="customerBirthPlace"
                            type="text"
                            autocomplete="off"
                            placeholder="Kraj"
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md col-xl">
                <div class="m-portlet">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title w-100 p-2">
                                <h3 class="m-portlet__head-text">Številka in kraj izdaje osebnega dokumenta</h3>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__body mf-portlet-custome__body mf-custom-type-ahead">
                        <input
                            formControlName="documentIdAndLocation"
                            type="text"
                            autocomplete="off"
                            placeholder="Številka in kraj"
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
