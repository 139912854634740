export enum PaperType {
    IZVID = 'IZVID',
    FTH_POROCILO = 'FTH_POROCILO',
    WHITE_REFERRAL = 'WHITE_REFERRAL',
    CONFIRM_VISIT = 'CONFIRM_VISIT',
    CONFIRM_RESERVATION = 'CONFIRM_RESERVATION',
    HELLODOC_REFERRAL = 'HELLODOC_REFERRAL',
    HELLODOC_IZVID = 'HELLODOC_IZVID',
    MFMEDICAL_IZVID = 'MFMEDICAL_IZVID',
    MEDIFIT_MEDICAL_REPORT = 'MEDIFIT_MEDICAL_REPORT',
    HELLODOC_ORDERFORM_LAB = 'HELLODOC_ORDERFORM_LAB',
    HELLODOC_ORDERFORM_DIAG = 'HELLODOC_ORDERFORM_DIAG',
    SURVEY_CUSTOMER = 'SURVEY_CUSTOMER',
    NAPREDNI_IZVID = 'NAPREDNI_IZVID',
    WORK_CERTIFICATE = 'WORK_CERTIFICATE',
    DRIVER_CERTIFICATE = 'DRIVER_CERTIFICATE',
    WEAPON_CERTIFICATE = 'WEAPON_CERTIFICATE',
}
