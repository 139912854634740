import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { PaperHistoryElement, PortfolioPaper, Result } from '../../../../_shared/model';
import { CustomerWorkDataDto } from '../../../../_shared/model/customer/customer-work-data.model';
import { WhiteReferralApi } from '../../../../_shared/model/portfolio/whiteReferralApi.model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { ICertificateDriverModel } from '../portfolio-papers/papers/pf-p-mdps-certificate/model/certificate-drivers.model';
import { ICertificateWeaponModel } from '../portfolio-papers/papers/pf-p-mdps-certificate/model/certificate-weapons.model';
import { ICertificateWorkModel } from '../portfolio-papers/papers/pf-p-mdps-certificate/model/certificate-work.model';
import { GenericReferralApi } from './../../../../_shared/model/portfolio/genericReferralApi.model';

@Injectable({
    providedIn: 'root',
})
export class ResultService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    //saveDocumentTemplate
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveDocumentTemplate(contractorId: number, templateName: string, data: Result): Observable<any> {
        return this.httpClient.put(this.helper.getDocumentUrl() + `/contractors/${contractorId}/documentTemplates/${templateName}`, data);
    }
    //getDocumentTemplates
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDocumentTemplates(contractorId: number, paperType: string): Observable<any> {
        return this.httpClient.get(this.helper.getDocumentUrl() + `/contractors/${contractorId}/documentTemplates/` + paperType);
    }
    //deleteDocumentTemplates
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteDocumentTemplates(contractorId: number, templateId: string): Observable<any> {
        return this.httpClient.delete(this.helper.getDocumentUrl() + `/contractors/${contractorId}/documentTemplates/${templateId}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addSurvey(visitId: number, surveys: Result): Observable<any> {
        //DEPRICATED
        //return this.httpClient.put(this.helper.getVisitUrl() + '/visits/' + visitId + '/surveys', surveys);
        return this.httpClient.put(this.helper.getVisitUrl(2) + '/visits/' + visitId + '/surveys', surveys);
    }

    //api/contractors/54/customers/1404/surveys
    // booking/eav2/erp/api/contractors/54/customers/1404/surveys \
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addCustomerSurvey(contractorId: number, customerId: number, surveys: Result): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/surveys`, surveys);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveReservationConfirmaton(reservationId: string, surveys: Result, surveyId?): Observable<any> {
        if (surveyId) {
            return this.httpClient.put(this.helper.getApiUrl(2) + `/reservation/${reservationId}/surveys/${surveyId}`, surveys);
        } else {
            return this.httpClient.put(this.helper.getApiUrl(2) + '/reservation/' + reservationId + '/surveys', surveys);
        }
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getReservationConfirmation(paperId): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/surveys/${paperId}`);
    }

    public getAllSurveys(visitId: number): Observable<Result> {
        return this.httpClient.get(this.helper.getVisitUrl() + '/visits/' + visitId + '/surveys');
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateSurvey(visitId: number, sets: Result): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + '/visits/' + visitId + '/surveys/', sets);
    }

    //brisemo izvid
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    public deleteSurvey(visitId: number, surveyId: string): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId + '/surveys');
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSurveyPreview(visitId: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + '/visits/' + visitId + '/surveys');
    }

    //printDiagnose()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSurveyPaperPreview(visitId: number, paperid: number, signature: boolean = false): Observable<any> {
        let url = `/visits/${visitId}/surveys/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSurveyPaperPreviewReservation(prereservationId: string, paperid: number, signature: boolean = false): Observable<any> {
        let url = `/reservations/${prereservationId}/surveys/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSurveyPaperPreviewReservationMasked(prereservationId: string, paperid: string, signature: boolean = false): Observable<any> {
        //masked -> paperId is a GUI instead of a number
        let url = `/reservations/${prereservationId}/masked/surveys/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getVisitProof(visitId: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + '/visits/' + visitId + '/termin');
    }

    //getVisitPortfolio
    public getVisitPortfolio(contractorId: number, visitId: number): Observable<PortfolioPaper[]> {
        return this.httpClient.get(this.helper.getVisitUrl(2) + `/contractors/${contractorId}/visits/${visitId}/portfolio`);
    }

    public getReservationPortfolio(contractorId: number, prereservationId: string): Observable<PortfolioPaper[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/reservations/${prereservationId}/portfolio`);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteReservationPortfolio(contractorId: number, prereservationId: string, paperId: number): Observable<any> {
        return this.httpClient.delete(
            this.helper.getVisitUrl() + `/contractors/${contractorId}/prereservation/${prereservationId}/portfolio/${paperId}`,
        );
    }

    //getVisitSurveys()
    public getSurveyPaper(visitId: number, paperid: number): Observable<Result> {
        return this.httpClient.get(this.helper.getVisitUrl(2) + `/visits/${visitId}/surveys/${paperid}`);
    }

    public getCustomerSurveyPaper(contractorId: number, customerId: number, paperid: number): Observable<Result> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/surveys/${paperid}`);
    }

    public updateCustomerSurveyPaper(contractorId: number, customerId: number, paperId: number, paper: Result): Observable<Result> {
        return this.httpClient.put(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/surveys/${paperId}`, paper);
    }

    //updateWholeVisitSurvey()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateSurveyPaper(visitId: number, surveyid: number, sets: Result): Observable<any> {
        //DEPRICATED
        //return this.httpClient.put(this.helper.getVisitUrl() + `/visits/${visitId}/surveys/${surveyid}`, sets);
        return this.httpClient.put(this.helper.getVisitUrl(2) + `/visits/${visitId}/surveys/${surveyid}`, sets);
    }

    // localhost:9090/booking/erp/visitapi/contractors/54/visits/55931/surveys/1523/signed
    //localhost:9190/booking/eav2/erp/api/contractors/54/papers/1523/signed

    //podpisovanje dokumentov znotraj eA
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSignedPaper(contractorId: number, visitId: number, paperId: number): Observable<any> {
        return this.httpClient.getAttachmentPost(this.helper.getApiUrl(2) + `/contractors/${contractorId}/papers/${paperId}/signed`);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAllSignedFromEa(contractorId: number, paperId: number, bsId?, caseId?): Observable<any> {
        let allSignedUrl = this.helper.getVisitUrl() + `/contractors/${contractorId}/surveys/${paperId}/signed`;
        if (bsId && caseId) {
            allSignedUrl += `/?bsid=${bsId}&caseid=${caseId}`;
        }
        return this.httpClient.get(allSignedUrl);
    }
    public getLastSigned(contractorId: number, paperId: number): Observable<string> {
        return this.httpClient.get(this.helper.getDocumentUrl() + `/contractors/${contractorId}/surveys/${paperId}`, {
            responseType: 'text',
        });
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSingleSigned(contractorId: number, documentId: string): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getDocumentUrl() + `/contractors/${contractorId}/documents/${documentId}`);
    }

    public getWhiteReferral(contractorid: number, paperid: number): Observable<WhiteReferralApi> {
        return this.httpClient.get(this.helper.getVisitUrl(2) + `/contractors/${contractorid}/white-referral/${paperid}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getPortfolioPaper(contractorId: number, paperid: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + `/contractors/${contractorId}/portfolioPaper/${paperid}`);
    }

    //searchRDP()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public searchRDP(query: string): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/rdp/search?query=${query}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public printPortfolioPaper(contractorid: number, paperid: number, signature: boolean = false): Observable<any> {
        let url = `/contractors/${contractorid}/portfolioPaper/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public printWhiteReferralMasked(contractorid: number, paperid: string, signature: boolean = false): Observable<any> {
        //masked -> paperId is a GUI instead of a number
        let url = `/contractors/${contractorid}/masked/white-referral/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }

    //updateWhiteReferral()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateWhiteReferral(contractorid: number, visitId: number, paperid: number, data: WhiteReferralApi): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + `/contractors/${contractorid}/visits/${visitId}/white-referral/${paperid}`, data);
    }

    //updateWhiteReferral()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public newWhiteReferral(contractorid: number, visitId: number, data: WhiteReferralApi | GenericReferralApi): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + `/contractors/${contractorid}/visits/${visitId}/white-referral`, data);
    }
    //getCustomerPapersHistory()
    public getCustomerPapersHistory(
        contractorId: number,
        customerId: number,
        papertype: string,
        from?: string,
        to?: string,
    ): Observable<PaperHistoryElement[]> {
        if (!from || !to) {
            return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/papers/${papertype}/`);
        }
        return this.httpClient.get(
            this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/papers/${papertype}?form=${from}&to=${to}`,
        );
    }

    public saveWorkCertificate(contractorId: number, visitId: number, data: ICertificateWorkModel): Observable<string> {
        return this.httpClient.post(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/workCertificate`), data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateWorkCertificate(contractorId: number, visitId: number, paperId: number, data: ICertificateWorkModel): Observable<any> {
        return this.httpClient.put(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/workCertificate/${paperId}`),
            data,
        );
    }

    public getWorkCertificateData(contractorId: number, visitId: number, workCertificateId: number): Observable<ICertificateWorkModel> {
        return this.httpClient.get(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/workCertificateData/${workCertificateId}`),
        );
    }

    public saveWeaponCertificate(contractorId: number, visitId: number, data: ICertificateWeaponModel): Observable<string> {
        return this.httpClient.post(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/weaponCertificate`), data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateWeaponCertificateData(contractorId: number, visitId: number, paperId: number, data: ICertificateWeaponModel): Observable<any> {
        return this.httpClient.put(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/weaponCertificate/${paperId}`),
            data,
        );
    }

    public getWeaponCertificateData(contractorId: number, visitId: number, weaponCertificateId: number): Observable<ICertificateWeaponModel> {
        return this.httpClient.get(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/weaponCertificateData/${weaponCertificateId}`),
        );
    }

    public saveDriverCertificate(contractorId: number, visitId: number, data: ICertificateDriverModel): Observable<string> {
        return this.httpClient.post(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/driverCertificate`), data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateDriverCertificateData(contractorId: number, visitId: number, paperId: number, data: ICertificateDriverModel): Observable<any> {
        return this.httpClient.put(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/driverCertificate/${paperId}`),
            data,
        );
    }

    public getDriverCertificateData(contractorId: number, visitId: number, driverCertificateId: number): Observable<ICertificateDriverModel> {
        return this.httpClient.get(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/driverCertificateData/${driverCertificateId}`),
        );
    }

    public getCustomerWorkData(contractorId: number, visitId: number): Observable<CustomerWorkDataDto> {
        return this.httpClient.get(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/visits/${visitId}/customerWorkData`));
    }
}
