<div
    *ngIf="
        paper.paperType != paperTypeEnum.WHITE_REFERRAL &&
        paper.paperType != paperTypeEnum.CONFIRM_VISIT &&
        paper.paperType != paperTypeEnum.HELLODOC_REFERRAL &&
        paper.paperType != paperTypeEnum.HELLODOC_ORDERFORM_LAB &&
        paper.paperType != paperTypeEnum.HELLODOC_ORDERFORM_DIAG &&
        paper.paperType != paperTypeEnum.CONFIRM_RESERVATION &&
        paper.paperType != paperTypeEnum.WORK_CERTIFICATE &&
        paper.paperType != paperTypeEnum.DRIVER_CERTIFICATE &&
        paper.paperType != paperTypeEnum.WEAPON_CERTIFICATE &&
        paper.paperType != paperTypeEnum.NAPREDNI_IZVID
    "
    class="m-portlet"
>
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text" i18n="@@visit.pf.templates.savedTemplates">Shranjene predloge</h3>
            </div>
        </div>
        <div class="m-portlet__head-tools">
            <a (click)="saveTemplate()" ngbTooltip="Dodaj predlogo" class="mf-cursor-pointer">
                <i class="la la-plus"></i>
            </a>
        </div>
    </div>
    <ng-container *ngIf="allTemplates.length > 0">
        <div class="m-portlet__body">
            <div class="list-group pb-2">
                <a
                    *ngFor="let item of allTemplates"
                    (click)="$event.preventDefault(); selectTemplate(item)"
                    href="#"
                    class="list-group-item list-group-item-action"
                >
                    {{ item.name }}
                </a>
            </div>
            <button
                *ngIf="selectedTemplate"
                (click)="deleteTemplate()"
                class="btn btn-outline-danger m-btn m-btn--pill btn-block"
                i18n="@@visit.pf.templates.delete"
            >
                Izbriši izbrano predlogo
            </button>
        </div>
    </ng-container>
</div>
