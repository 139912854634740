import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { MfToastService } from '../../../_shared/services/mfToast.service';
import { DocumentSign } from './../../../_shared/model/portfolio/documentSign.model';
import { SafePipe } from './../../../_shared/pipes/safe.pipe';
import { DocumentNavigationService } from './../../../_shared/services/documentNavigation.service';
@Component({
    selector: 'app-media-view-popup',
    templateUrl: './media-view-popup.component.html',
    styleUrls: ['./media-view-popup.component.scss'],
})
export class MediaViewPopupComponent implements OnInit {
    @Input()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set setdocumentFile(data: any) {
        this.documentFile = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        this.blobData = data;
        if (data) {
            if (data.type.includes('video')) {
                this.isVideo = true;
                this.videoPlayer.element.nativeElement.src = this.documentFile;
            } else if (data.type == 'text/plain') {
                const reader = new FileReader();
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const that = this;
                reader.onload = function () {
                    that.documentFile = reader.result;
                };
                reader.readAsText(data, 'ISO-8859-1');
            } else if (data.type.includes('pdf')) {
                this.pdfFullyLoaded = false;
                const reader = new FileReader();
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const that = this;
                reader.readAsText(this.blobData, 'UTF-8');
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                reader.onload = function (evt: any) {
                    if (evt.target.result.toString().includes('Encrypt')) {
                        that.encryptedFile = true;
                        that.displayAlert(' Zaščitena datoteka \n Vnesite geslo', '', that.alertSettings).then(result => {
                            if (result.isDismissed) {
                                that.activeModal.dismiss('close-no-error');
                            } else if (result.value) {
                                that.password = result.value;
                                that.fileReady = true;
                                that.iframe.element.nativeElement.src = that.documentFile;
                            } else {
                                that.fileReady = true;
                            }
                        });
                    } else {
                        that.iframe.element.nativeElement.src = that.documentFile;
                        that.fileReady = true;
                    }
                };
            } else {
                //unknown type --> omogoči download
            }
        }
    }
    @Input()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    documentType: any;

    @Input()
    fileName: string;

    @ViewChild('blob', { read: ViewContainerRef, static: true })
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    iframe: any;

    @ViewChild('videoPlayer', { read: ViewContainerRef, static: true })
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videoPlayer: any;

    @Input()
    signature: DocumentSign;

    @Input()
    print = false;

    @Input()
    qrSettings: { url: string; size: number };

    // @Output() public action: Subject<any> = new Subject();
    public pdfFullyLoaded = false;
    public isVideo = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public blobData: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public documentFile: any;
    public zoom = 0.6;
    public fullScreenEnabled = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public widthPic: any;
    public width = 50;
    public navigationButtons = false;
    //spremenljivke za zaščitene datoteke
    public encryptedFile = false;
    public password: string;
    public fileReady = false;
    public failedPassword = false;
    public alertSettings = {
        html: `
    <div class="row">
    <div class="col-12">
    <input type="password" class="form-control m-input mf-password" autocomplete='off'>
    </div>
    </div>`,
        preConfirm: () => {
            const pass = (document.querySelector('.mf-password') as HTMLInputElement).value;
            return pass;
        },
    };
    constructor(
        public activeModal: NgbActiveModal,
        private mfToast: MfToastService,
        private docuNavigation: DocumentNavigationService,
        private safePipe: SafePipe,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    public checkIfEmpty(signature: DocumentSign): boolean {
        return _.compact(_.values(signature)).length < 1;
    }
    //ko klikneš drugam kakor pdf/slika, ti zapre popup
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public clickEvent(event: any) {
        if (
            event.srcElement.className === 'textLayer' ||
            event.srcElement.className === 'center' ||
            event.srcElement.className === 'd-flex' ||
            event.srcElement.className === 'm--font-boldest' ||
            event.srcElement.className.includes('mf-text-plain-view') ||
            event.srcElement.className === ''
        ) {
            return;
        } else {
            this.activeModal.dismiss('close-no-error');
        }
    }
    // public nextFile(data: string) {
    //     this.action.next({ action: data, data: this.blobData });
    // }

    public appendDocumentToCase(): void {
        this.activeModal.close({ action: 'appendDocumentToCase' });
    }

    // public test(): void {
    //     console.log('test');
    // }
    public zoom_in() {
        if (this.documentType === 'application/pdf') {
            this.zoom += 0.25;
        }
        if (this.documentType.includes('image')) {
            if (this.width == 100) {
                return;
            }
            this.width += 10;
            this.widthPic = this.width + '%';
        }
    }

    public zoom_out() {
        if (this.documentType === 'application/pdf') {
            if (this.zoom == 0.25) {
                return;
            }
            this.zoom -= 0.25;
        }
        if (this.documentType.includes('image')) {
            if (this.width == 10) {
                return;
            }
            this.width -= 10;
            this.widthPic = this.width + '%';
        }
    }
    public fullscreen() {
        if (this.documentType === 'application/pdf') {
            if (!this.fullScreenEnabled) {
                this.zoom = 1;
                this.fullScreenEnabled = true;
            } else {
                this.zoom = 0.6;
                this.fullScreenEnabled = false;
            }
        }
    }

    public downloadFile(): void {
        let data = this.blobData;
        if (this.qrSettings) {
            data = document.querySelector('canvas')?.toDataURL();
        }
        FileSaver.saveAs(data, this.fileName);
    }

    public printFile(): void {
        if (this.encryptedFile) {
            this.mfToast.warning('Tiskanje zaščitenih datotek ni dovoljeno.');
            this.downloadFile();
        } else if (this.qrSettings) {
            const imgData = document.querySelector('canvas').toDataURL('image/png');
            this.iframe.element.nativeElement.contentWindow.document.write(
                `<title> QR koda </title>
                <img src="${imgData}" />`,
            );
            setTimeout(() => {
                this.iframe.element.nativeElement.contentWindow.focus();
                this.iframe.element.nativeElement.contentWindow.print();
                this.iframe.element.nativeElement.contentWindow.document.close('');
            }, 0);
        } else {
            if (!(navigator?.platform?.toUpperCase()?.indexOf('MAC') >= 0)) {
                //not IOS/MAC
                try {
                    this.iframe.element.nativeElement.contentWindow.focus();
                    this.iframe.element.nativeElement.contentWindow.print();
                } catch (err) {
                    this.mfToast.warning('Napaka pri tiskanju dokumenta.');
                    this.downloadFile();
                }
            } else {
                //Mac -> direct download
                this.downloadFile();
            }
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public pdfLoadingComplete(data): void {
        this.pdfFullyLoaded = true;
        if (this.print) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            setTimeout(function () {
                that.printFile();
            }, 1500);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public showError(event) {
        if (this.encryptedFile) {
            this.failedPassword = true;
            this.fileReady = false;
        }
    }
    public reEnterPassword() {
        this.displayAlert(' Zaščitena datoteka \n Vnesite geslo', '', this.alertSettings).then(result => {
            if (result.value) {
                this.failedPassword = false;
                this.fileReady = true;
                this.password = result.value;
                this.iframe.element.nativeElement.src = this.documentFile;
            }
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayAlert(title: string, content: string, settings?: any): Promise<any> {
        return Swal.fire(
            Object.assign(
                {
                    title: title,
                    text: content,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Potrdi',
                    cancelButtonText: 'Prekliči',
                    reverseButtons: true,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-accent m-btn m-btn--custom m-btn--pill',
                    confirmButtonColor: null,
                    cancelButtonClass: 'btn btn-danger m-btn m-btn--custom m-btn--pill',
                    cancelButtonColor: null,
                },
                settings,
            ),
        );
    }
}
