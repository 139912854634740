import { Pipe, PipeTransform } from '@angular/core';
import { PaperType } from '../enums/PaperType.enum';
import { StorageService } from '../services';

@Pipe({
    name: 'paperName',
})
export class PaperNamePipe implements PipeTransform {
    constructor(private storage: StorageService) {}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(type: any, args?: any): any {
        if (args === 'short') {
            switch (type) {
                case PaperType.IZVID:
                case PaperType.SURVEY_CUSTOMER:
                    return this.storage.getSettings()?.paperTypeSettings?.find(x => x.paperType === type)?.title || 'Izvid';
                case PaperType.FTH_POROCILO:
                    return 'FT poročilo';
                case PaperType.WHITE_REFERRAL:
                    return 'Bela napotnica';
                case PaperType.HELLODOC_REFERRAL:
                    return 'HD napotnica';
                case PaperType.HELLODOC_IZVID:
                    return 'HD izvid';
                case PaperType.MFMEDICAL_IZVID:
                case PaperType.MEDIFIT_MEDICAL_REPORT:
                    return 'MF Medical izvid';
                case PaperType.CONFIRM_VISIT:
                    return 'Potrdilo o obisku';
                case PaperType.CONFIRM_RESERVATION:
                    return 'Potrdilo o terminu';
                case PaperType.HELLODOC_ORDERFORM_LAB:
                    return 'LAB naročilnica';
                case PaperType.HELLODOC_ORDERFORM_DIAG:
                    return 'DIAG naročilnica';
                case PaperType.NAPREDNI_IZVID:
                    return 'Napredni izvid';
                case PaperType.WORK_CERTIFICATE:
                    return 'Spričevalo za delo';
                case PaperType.DRIVER_CERTIFICATE:
                    return 'Spričevalo za voznike';
                case PaperType.WEAPON_CERTIFICATE:
                    return 'Spričevalo za orožje';

                default:
                    return type;
            }
        }
        switch (type) {
            case PaperType.IZVID:
            case PaperType.SURVEY_CUSTOMER:
                return this.storage.getSettings()?.paperTypeSettings?.find(x => x.paperType === type)?.title || 'Izvid';
            case PaperType.FTH_POROCILO:
                return 'Fizioterapevtsko poročilo';
            case PaperType.WHITE_REFERRAL:
                return 'Bela napotnica';
            case PaperType.HELLODOC_REFERRAL:
                return 'Halo Doktor napotnica';
            case PaperType.HELLODOC_ORDERFORM_LAB:
                return 'Laboratorijska naročilnica';
            case PaperType.HELLODOC_ORDERFORM_DIAG:
                return 'Diagnostična naročilnica';
            case PaperType.HELLODOC_IZVID:
                return 'Halo Doktor izvid';
            case PaperType.MFMEDICAL_IZVID:
            case PaperType.MEDIFIT_MEDICAL_REPORT:
                return 'Medifit Medical izvid';
            case PaperType.CONFIRM_VISIT:
                return 'Potrdilo o obisku';
            case PaperType.CONFIRM_RESERVATION:
                return 'Potrdilo o terminu';
            case PaperType.NAPREDNI_IZVID:
                return 'Napredni izvid';
            case PaperType.WORK_CERTIFICATE:
                return 'Spričevalo za delo';
            case PaperType.DRIVER_CERTIFICATE:
                return 'Spričevalo za voznike';
            case PaperType.WEAPON_CERTIFICATE:
                return 'Spričevalo za orožje';
            default:
                return type;
        }
    }
}
