export enum CommunicationType {
    REMINDER = 'REMINDER',
    SURVEY = 'SURVEY',
    INVOICE_ACCESS = 'INVOICE_ACCESS',
    // DIAGNOSIS_ACCESS = 'DIAGNOSIS_ACCESS',
    DOCUMENTATION_ACCESS = 'DOCUMENTATION_ACCESS',
    RESERVATION_NOTICE = 'RESERVATION_NOTICE',
    RESERVATION_CHANGE_NOTICE = 'RESERVATION_CHANGE_NOTICE',
    RESERVATION_REMINDER = 'RESERVATION_REMINDER',
    RESERVATION_CANCEL = 'RESERVATION_CANCEL',
    ACCESS_CODE = 'ACCESS_CODE',
    SLOT_RESERVATION = 'SLOT_RESERVATION',
    NEW_SUBCONTRACTOR_REQUEST = 'NEW_SUBCONTRACTOR_REQUEST',
    PWD_RENEWAL = 'PWD_RENEWAL',
    // PASSWORD_RENEWAL = 'PASSWORD_RENEWAL',
    GDPR_PERMISSION = 'GDPR_PERMISSION',
    TELEMEDICINE = 'TELEMEDICINE',
    COVID19 = 'COVID19',
    UNANSWERED_CALL = 'UNANSWERED_CALL',
    INCOMING_CALL = 'INCOMING_CALL',
    OUTGOING_CALL = 'OUTGOING_CALL',
    HELLO_DOC_REGISTER = 'HELLO_DOC_REGISTER',
    PROMOTION_COUPON = 'PROMOTION_COUPON',
    PATIENT_BOOKING = 'PATIENT_BOOKING',
    CUSTOMER_SHARED_DATA = 'CUSTOMER_SHARED_DATA',
    HD_CUSTOMER_UNREAD_CHAT = 'HD_CUSTOMER_UNREAD_CHAT',
}

// REMINDER,
// SURVEY,
// INVOICE_ACCESS,
// DIAGNOSIS_ACCESS,
// DOCUMENTATION_ACCESS,
// RESERVATION_NOTICE,
// RESERVATION_CHANGE_NOTICE,
// RESERVATION_REMINDER,
// RESERVATION_CANCEL,
// ACCESS_CODE,
// SLOT_RESERVATION,
// NEW_SUBCONTRACTOR_REQUEST,
// PWD_RENEWAL,
// PASSWORD_RENEWAL,
// GDPR_PERMISSION,
