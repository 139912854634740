<div class="m-portlet">
    <form [formGroup]="documentsForm" (ngSubmit)="onSubmitAddDocument()" novalidate>
        <div class="m-portlet__head pr-1">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title d-flex align-items-center">
                    <h3 class="m-portlet__head-text mb-0">{{ settings?.title }}</h3>
                </div>
            </div>
            <div *ngIf="settings?.canUpload" class="m-portlet__head-tools">
                <ul role="tablist" style="border: none" class="nav nav-tabs m-tabs m-tabs-line m-tabs-line--right m-tabs-line-danger">
                    <li *ngIf="!isVisibleInput" class="nav-item m-tabs__item mr-2 mf-cursor-pointer">
                        <a (click)="isVisibleInput = !isVisibleInput" aria-selected="false" class="nav-link m-tabs__link border-0">
                            <i class="la la-plus"></i>
                            <span class="m--font-brand"></span>
                        </a>
                    </li>
                    <li *ngIf="isVisibleInput" class="nav-item m-tabs__item mr-2 mf-cursor-pointer">
                        <a (click)="isVisibleInput = !isVisibleInput" aria-selected="false" class="nav-link m-tabs__link border-0">
                            <i class="la la-minus"></i>
                            <span class="m--font-brand"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="m-portlet__body">
            <div *ngIf="isVisibleInput" [@expandCollapseHeight] class="upload">
                <div class="m-list-search pb-2">
                    <div class="m-list-search__results">
                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i ngbTooltip="Ime datoteke" class="la la-paperclip m--font-inverse-light"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <div class="custom-file">
                                    <input
                                        (change)="selectFile($event)"
                                        type="file"
                                        formControlName="uploadFile"
                                        value=""
                                        class="custom-file-input"
                                    />
                                    <label *ngIf="!selectedFilesForUpload" for="customFile" style="overflow: hidden" class="custom-file-label">
                                        Izberi datoteko
                                    </label>
                                    <label *ngIf="selectedFilesForUpload" for="customFile" style="overflow: hidden" class="custom-file-label">
                                        {{ selectedFilesForUpload[0]?.name }}
                                    </label>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div *ngIf="percentDone" class="progress m-progress--sm">
                        <div
                            [style.width.%]="percentDone"
                            aria-valuemax="100"
                            aria-valuemin="0"
                            aria-valuenow="100"
                            role="progressbar"
                            class="progress-bar m--bg-primary"
                        ></div>
                    </div>

                    <button
                        type="submit"
                        class="m--align-right mt-2 mb-2 btn btn-outline-accent m-btn m-btn--custom m-btn--icon m-btn--pill btn-block"
                    >
                        <span>
                            <i class="la la-check"></i>
                            <span>Naloži</span>
                        </span>
                    </button>
                </div>
                <hr />
            </div>
            <div class="m-widget4">
                <ng-container *ngIf="documents$ | async as docs; else noDocumentsTemplate">
                    <ng-container *ngIf="docs.length > 0; else noDocumentsTemplate">
                        <common-document-list-element
                            *ngFor="let doc of docs"
                            [document]="doc"
                            [isReadOnly]="doc.isReadOnly"
                            [showCreationLocation]="true"
                            (onOpenDocument)="openMediaViewDialog(doc)"
                            (onDelete)="removeDocumetFromlist(doc)"
                            (onDownloadDocument)="downloadDocument(doc)"
                            class="document-element"
                        ></common-document-list-element>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </form>
</div>

<ng-template #noDocumentsTemplate>Ni dokumentov.</ng-template>
