import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReservationExaminationResponseDto } from '../../model/examinations/examination-response-dto';
import { ReservationExaminationFieldDto } from '../../model/examinations/reservation-examination-field-dto';
import { SaveReservationExaminationFieldsRequestDto } from '../../model/examinations/save-reservation-examination-fields-request-dto';
import { HelperService } from '../helper.service';
import { HttpClientService } from '../http-client.service';

@Injectable({
    providedIn: 'root',
})
export class ExaminationsClientService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    public getContractorExaminations(contractorId: number): Observable<ReservationExaminationResponseDto[]> {
        return this.httpClient.get(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/examinations`));
    }

    public getReservationExaminations(contractorId: number, prereservationId: string): Observable<ReservationExaminationResponseDto[]> {
        return this.httpClient.get(this.helper.getExaminationApiUrl(`/contractors/${contractorId}/reservations/${prereservationId}/examinations`));
    }

    public createReservationExamination(contractorId: number, prereservationId: string, examinationId: number): Observable<number> {
        return this.httpClient.post(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/reservations/${prereservationId}/examinations/${examinationId}`),
        );
    }

    public deleteReservationExamination(contractorId: number, prereservationId: string, examinationId: number): Observable<number> {
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(`/contractors/${contractorId}/reservations/${prereservationId}/examinations/${examinationId}`),
        );
    }

    public saveReservationExaminationFields(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        requestBody: SaveReservationExaminationFieldsRequestDto,
    ): Observable<ReservationExaminationFieldDto[]> {
        return this.httpClient.post(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/fields`,
            ),
            requestBody,
        );
    }

    public deleteReservationExaminationField(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        fieldId: number,
    ): Observable<number> {
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/fields/${fieldId}`,
            ),
        );
    }

    public saveReservationExaminationComment(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        comment: string,
    ): Observable<number> {
        return this.httpClient.post(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/comment`,
            ),
            comment,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    public deleteReservationExaminationComment(contractorId: number, prereservationId: string, resExaminationId: number): Observable<number> {
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/comment`,
            ),
        );
    }

    public saveReservationExaminationDocument(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        maskedDocumentId: string,
    ): Observable<string> {
        const httpOptions = {
            responseType: 'text',
        };
        return this.httpClient.post(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/document/${maskedDocumentId}`,
            ),
            undefined,
            httpOptions,
        );
    }

    public deleteReservationExaminationDocument(
        contractorId: number,
        prereservationId: string,
        resExaminationId: number,
        maskedDocumentId: string,
    ): Observable<string> {
        const httpOptions = {
            responseType: 'text',
        };
        return this.httpClient.delete(
            this.helper.getExaminationApiUrl(
                `/contractors/${contractorId}/reservations/${prereservationId}/resexaminations/${resExaminationId}/document/${maskedDocumentId}`,
            ),
            httpOptions,
        );
    }
}
